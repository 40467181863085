import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import defaultShield from 'assets/club/shield_none.png';
import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, BodyS } from 'atoms/typography';
import { glossary } from 'lib/glossary';

const Logo = styled.img`
  width: var(--quadruple-unit);
`;

type Props = { displayName: string; pictureUrl: string | null; to: string };

export const OfficialProduct = ({ displayName, pictureUrl, to }: Props) => {
  return (
    <FullWidthContainer>
      <Horizontal gap={1}>
        <Logo src={pictureUrl || defaultShield} alt={displayName} />
        <Vertical gap={0}>
          <Link to={to}>
            <BodyM as="p">{displayName}</BodyM>
          </Link>
          <BodyS as="p" color="var(--c-nd-600)">
            <FormattedMessage {...glossary.officiallyLicensedProduct} />
          </BodyS>
        </Vertical>
      </Horizontal>
    </FullWidthContainer>
  );
};
