import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';

import {
  CartItemState,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useMutation } from '@sorare/core/src/hooks/graphql/useMutation';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import type {
  AddToCartMutation,
  AddToCartMutationVariables,
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables,
  SaveForLaterMutation,
  SaveForLaterMutationVariables,
  useCart_anyCard,
  useCart_cart,
  useCart_cartItem,
} from './__generated__/useCart.graphql';

const anyCardFragment = gql`
  fragment useCart_anyCard on AnyCardInterface {
    slug
    sport
    rarityTyped
    anyPlayer {
      slug
    }
  }
` as TypedDocumentNode<useCart_anyCard>;

const cartItemFragment = gql`
  fragment useCart_cartItem on CartItem {
    id
    card {
      slug
      sport
      ...useCart_anyCard
    }
    state
    price {
      ...MonetaryAmountFragment_monetaryAmount
    }
  }
  ${anyCardFragment}
  ${monetaryAmountFragment}
` as TypedDocumentNode<useCart_cartItem>;

const cartFragment = gql`
  fragment useCart_cart on Cart {
    id
    sport
    price {
      ...MonetaryAmountFragment_monetaryAmount
    }
    mainCartItems {
      id
      ...useCart_cartItem
    }
    savedForLaterCartItems {
      id
      ...useCart_cartItem
    }
    affectedCardCollections {
      id
      scoreIncrease
      bonusIncrease
    }
  }
  ${cartItemFragment}
  ${monetaryAmountFragment}
` as TypedDocumentNode<useCart_cart>;

const ADD_TO_CART_MUTATION = gql`
  mutation AddToCartMutation($addToCartInput: addToCartInput!) {
    addToCart(input: $addToCartInput) {
      cart {
        id
        ...useCart_cart
      }
    }
  }
  ${cartFragment}
` as TypedDocumentNode<AddToCartMutation, AddToCartMutationVariables>;

const REMOVE_FROM_CART_MUTATION = gql`
  mutation RemoveFromCartMutation($removeFromCartInput: removeFromCartInput!) {
    removeFromCart(input: $removeFromCartInput) {
      cart {
        id
        ...useCart_cart
      }
    }
  }
  ${cartFragment}
` as TypedDocumentNode<RemoveFromCartMutation, RemoveFromCartMutationVariables>;

const SAVE_FOR_LATER_MUTATION = gql`
  mutation SaveForLaterMutation($saveForLaterInput: saveForLaterInput!) {
    saveForLater(input: $saveForLaterInput) {
      cart {
        id
        ...useCart_cart
      }
    }
  }
  ${cartFragment}
` as TypedDocumentNode<SaveForLaterMutation, SaveForLaterMutationVariables>;

export const useCart = () => {
  const { currentUser } = useCurrentUserContext();
  const [addToCartMutation, { loading: addToCartMutationLoading }] =
    useMutation(ADD_TO_CART_MUTATION);
  const [removeFromCartMutation, { loading: removeFromCartMutationLoading }] =
    useMutation(REMOVE_FROM_CART_MUTATION);
  const [saveForLaterMutation, { loading: saveForLaterMutationLoading }] =
    useMutation(SAVE_FOR_LATER_MUTATION);

  const cart = currentUser?.myCart;

  const addCardToCart = async ({ sport, slug }: useCart_anyCard) => {
    const res = await addToCartMutation({
      variables: {
        addToCartInput: {
          sport,
          cardSlugs: [slug],
        },
      },
    });
    return res.data?.addToCart?.cart;
  };

  const addCartItemsToCart = async (cartItemIds: string[], sport: Sport) => {
    const res = await addToCartMutation({
      variables: {
        addToCartInput: {
          cartItemIds,
          sport,
        },
      },
    });
    return res.data?.addToCart?.cart;
  };

  const removeFromCart = async (cartItemIds: string[]) => {
    if (!cart) return null;
    const res = await removeFromCartMutation({
      variables: {
        removeFromCartInput: {
          cartId: cart?.id || '',
          cartItemIds,
        },
      },
      optimisticResponse: {
        removeFromCart: {
          cart: {
            ...cart,
            id: cart?.id || '',
            mainCartItems: cart!.mainCartItems!.filter(
              i => !cartItemIds.includes(i.id)
            ),
            savedForLaterCartItems: cart!.savedForLaterCartItems!.filter(
              i => !cartItemIds.includes(i.id)
            ),
            __typename: 'Cart',
          },
          __typename: 'removeFromCartPayload',
        },
      },
    });
    return res.data?.removeFromCart?.cart;
  };

  const saveForLater = async (cartItemIds: string[]) => {
    if (!cart) return null;
    const res = await saveForLaterMutation({
      variables: {
        saveForLaterInput: {
          cartId: cart?.id || '',
          cartItemIds,
        },
      },
    });
    return res.data?.saveForLater?.cart;
  };

  const availableCartItems =
    cart?.mainCartItems?.filter(
      i => i.state && i.state !== CartItemState.REMOVED
    ) || [];

  const cartCount = availableCartItems?.length || 0;

  const removedCartCount =
    (cart?.mainCartItems?.length || 0) - (availableCartItems?.length || 0);

  const savedForLaterCount = cart?.savedForLaterCartItems?.length || 0;

  const cartTotal = cart?.price || undefined;
  const isInCart = ({ slug, rarityTyped, anyPlayer }: useCart_anyCard) =>
    currentUser?.myCart?.mainCartItems?.some(
      item =>
        item.card.slug === slug ||
        (item.card.rarityTyped === rarityTyped &&
          item.card.anyPlayer.slug === anyPlayer.slug)
    );

  return {
    addCardToCart: {
      loading: addToCartMutationLoading,
      mutate: addCardToCart,
    },
    addCartItemsToCart: {
      loading: addToCartMutationLoading,
      mutate: addCartItemsToCart,
    },
    removeFromCart: {
      loading: removeFromCartMutationLoading,
      mutate: removeFromCart,
    },
    saveForLater: {
      loading: saveForLaterMutationLoading,
      mutate: saveForLater,
    },
    isInCart,
    cartCount,
    removedCartCount,
    savedForLaterCount,
    cart,
    cartTotal,
  };
};

useCart.fragments = {
  anyCard: anyCardFragment,
  cartItem: cartItemFragment,
};
